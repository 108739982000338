<template>
  <section class="section vh100">
    <div v-if="isPaymentProcessing">
      <b-notification type="is-warning" :closable="false" role="alert">
        <h1 class="title">Upss..</h1>
        <p>Niestety nie możemy obecnie obsłużyc płatności i powtórzyć losowania.</p>
      </b-notification>

      <ul class="options">
        <li>
          <button
            type="button"
            class="button is-primary"
            style="width: 100%;"
            @click="acceptWithoutCancel"
          >
            Wybieram poprzedni wynik
          </button>
        </li>
      </ul>
    </div>

    <div class="block" v-if="!isPaymentProcessing">
      <h1 class="title">Wystarczy {{ amount }}</h1>
      <p class="subtitle mt-1">i możesz losować ponownie.</p>

      <article class="panel is-primary has-background-white">
        <p class="panel-heading">
          Metoda płatności
        </p>
        <div class="p-3">
          <b-field>
            <b-radio v-model="paymentMethod" size="is-medium" native-value="sms">
              SMS
            </b-radio>
          </b-field>

          <b-field>
            <b-radio v-model="paymentMethod" size="is-medium" native-value="transfer">
              Szybki przelew i BLIK
            </b-radio>
          </b-field>

          <b-field>
            <b-radio v-model="paymentMethod" size="is-medium" native-value="card">
              Karta płatniczna
            </b-radio>
          </b-field>

          <p class="mt-3">Losując ponownie wspierasz akcje charytatywne</p>
        </div>
      </article>

      <ul class="options">
        <li>
          <button type="button" class="button" style="width: 100%;" @click="cancel">
            Wybieram poprzedni wynik
          </button>
        </li>
        <li>
          <button type="button" class="button is-primary" style="width: 100%;" @click="pay">
            Płacę
          </button>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      paymentMethod: "sms",
      isPaymentProcessing: false
    };
  },
  computed: {
    amount() {
      return "5 zł"; // make it configurable via firebase remoteconfig
    }
  },
  methods: {
    pay() {
      this.$analytics.logEvent("payment_pay", {
        amount: this.amount,
        method: this.paymentMethod
      });

      this.isPaymentProcessing = true;
      window.scrollTo(0, 0);
    },
    acceptWithoutCancel() {
      this.$store.dispatch("participant/acceptResult");
      window.scrollTo(0, 0);
    },
    cancel() {
      this.$analytics.logEvent("payment_cancel", {
        amount: this.amount,
        method: this.paymentMethod
      });
      this.$store.dispatch("participant/acceptResult");
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style></style>
