<template>
    <div class="scene">
        <h1 class="intro is-size-3">Złap prezent!</h1>
        <div class="mask"></div>
        <div class="elf" id="elf"></div>
    </div>
</template>

<script>
export default {
  mounted() {
        const scene = document.querySelector(".scene");

        function getRandomInt(min, max) {
            return Math.floor(Math.random() * (max - min + 1)) + min;
        }

        function createPresent() {
            const width = getRandomInt(4, 6) * 32;
            const present = document.createElement("div");
            present.classList.add("present");
            present.classList.add("present-" + getRandomInt(1,3));
            present.style.left = getRandomInt(0, window.innerWidth - width) + "px";
            present.style.animationDuration = getRandomInt(1500, 3000) + "ms";
            present.style.width = width + "px";
            present.style.height = present.style.width;
            present.style.transform = "rotate(" + getRandomInt(1, 20) * 7.5 + "deg)"
            scene.appendChild(present);

            present.addEventListener("animationend", () => {
                scene.removeChild(present);
            });
        }

        function throwPresents() {
            const presentsCount = getRandomInt(2, 4);
            for (let i = 0; i < presentsCount; i++) {
                createPresent();
            }
            setTimeout(throwPresents, getRandomInt(1500, 3000));
        }

        throwPresents();
  }
}
</script>

<style>
        .scene {
            background-image: url('/img/theme/presents/bkg.jpg');
            background-size: cover;
            width: 100%;
            height: 100vh;
            position: relative;
            overflow: hidden;
        }

        .mask {
            width: 100%;
            height: 100vh;
            background-color: black;
            opacity: 0.33;
        }

        .elf {
            background-color: transparent;
            background-image: url('/img/theme/presents/elf.png');
            background-size: cover;
            width: 192px; /* change depending on image */
            height: 192px;  /* change depending on image */
            position: absolute;
            top: 5%;
            left: 5%; /* change to reduce move - moveRight */
            animation: moveRight 4s linear infinite;
            border-bottom: 12px solid #423628;
        }

        h1.intro {
          position: absolute;
          bottom: 20%;
          text-align: center;
          width: 100%;
          background-color: #423628;
          opacity: 0.8;
          color: white;
          z-index: 100;
          padding: 12px 0;
          border-top: 1px solid white;
          border-bottom: 1px solid white;
        }

        @keyframes moveRight {
            0% {
                left: 5%;
                rotate: 15deg;
            }
            50% {
                left: calc(95% - 192px);
                rotate: -15deg;
            }
            100% {
                left: 5%;
                rotate: 15deg;
            }
        }

        .present-1 { background-image: url('/img/theme/presents/present-1.png'); }
        .present-2 { background-image: url('/img/theme/presents/present-2.png'); }
        .present-3 { background-image: url('/img/theme/presents/present-3.png'); }

        .present {
            background-color: transparent;
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
            animation: fall linear;
            animation-fill-mode: forwards;
            z-index: 200;
        }

        @keyframes fall {
            0% {
                top: 20%;
                width: 48px;
                height: 48px;
            }
            100% {
                top: 100vh;
            }
        }
</style>
