<template>
  <div class="drawer vh100">
    <div id="drawer-container"></div>
    <p
      style="bottom: 20%; position: absolute; width: 100%"
      class="has-text-centered has-text-white title font-cookie is-size-2"
    >
      Wybierz<br />
      skarpetkę
    </p>
  </div>
</template>

<script>
export default {
  mounted() {
    const drawerContainer = document.getElementById("drawer-container");
    // Function to open the drawer
    const openDrawer = () => {
      drawerContainer.style.top = "0px";
    };

    // Automatically open the drawer 500ms after the page is loaded
    setTimeout(openDrawer, 500);
  }
};
</script>

<style>
.drawer {
  background-image: url("/img/theme/drawer/bkg.jpg");
  background-size: 320px;
}

#drawer-container {
  position: fixed;
  top: -60%; /* Initially hide the drawer above the viewport */
  left: 5%;
  width: 90%;
  color: #fff;
  padding: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  transition: top 1s ease-in-out;
  height: 60%;
  border-bottom: 24px solid black;
  border-right: 4px solid black;
  border-left: 4px solid black;
  background-image: url("/img/theme/drawer/socks.jpg");
  background-size: cover;
  background-position-x: -15px;
  max-width: 800px;
}
</style>
