<template>
  <div>
    <Ad v-if="!adDisplayed" :ad="ad" :close="closeAd" />

    <div class="results" v-else>
      <p class="has-text-centered m-3">
        <a href="/">
          <span class="font-cookie is-size-4 has-text-black">
            swiatecznelosowanie.pl
          </span>
        </a>
      </p>

      <div class="tile is-ancestor is-top">
        <div class="tile is-parent">
          <article class="tile is-child box primary-background" style="height: 40vh;">
            <div class="result-name mb-4">
              <figure class="image is-128x128">
                <img src="/icons/gift-box.svg" style="filter: invert(1)" />
              </figure>

              <h2 class="subtitle mt-4">{{ participant.name }}, wylosowana osoba to ...</h2>
              <h1 class="title" style="font-size: 3rem">{{ result.name }}</h1>
            </div>
            <WishlistResult class="wishlist" :wishlist="result.wishlist" :name="result.name" />
          </article>
        </div>
      </div>

      <Redraw v-if="result.canRedraw" />

      <div class="tile is-ancestor" v-else>
        <div class="tile is-parent">
          <article class="tile is-child">
            <WishlistTile />
          </article>
        </div>
        <div class="tile is-vertical">
          <div class="tile is-parent" v-if="draw.intro">
            <article class="tile is-child box organizer-message">
              <p class="subtitle is-size-7 m-0 mb-1">
                <span class="icon-text">
                  <span>Od organizatora</span>
                  <span class="icon">
                    <i class="fa fa-envelope"></i>
                  </span>
                </span>
              </p>
              <p style="white-space: pre-wrap;">{{ draw.intro }}</p>
            </article>
          </div>
          <div class="tile is-parent">
            <article class="tile is-child box">
              <AdTile :ad="ad" />
            </article>
          </div>
        </div>
      </div>

      <p class="has-text-centered m-4">
        <a :href="organizeCTA" target="_blank">&#8594; Stwórz własne losowanie</a>
      </p>

      <!-- <section class="hero is-primary">
        <div class="section">
          <div class="block">
            <figure class="image is-128x128">
              <img src="/icons/gift-box.svg" style="filter: invert(1)" />
            </figure>
          </div>
          <h2 class="subtitle">{{ participant.name }}, wylosowana osoba to ...</h2>
          <h1 class="title" style="font-size: 3rem">{{ result.name }}</h1>
        </div>
      </section>

      <Redraw v-if="result.canRedraw" />

      <div v-if="!result.canRedraw || result.isAccepted">
        <aside class="menu section">
          <p class="menu-label">Co dalej?</p>
          <ul class="menu-list">
            <WishlistResult :wishlist="result.wishlist" :name="result.name" />
            <li><a @click="pdf">&#8594; Pobierz wynik jako PDF</a></li>
            <li v-if="ad">
              <a :href="ad.url" target="_blank">&#8594; {{ ad.title }}</a>
            </li>
            <li><a :href="organizeCTA" target="_blank">&#8594; Stwórz własne losowanie</a></li>
          </ul>
          <div v-if="draw.intro">
            <p class="menu-label mt-3">Od organizatora</p>
            <b-message style="white-space: pre-wrap;">{{ draw.intro }}</b-message>
          </div>
        </aside>
      </div> -->
    </div>
  </div>
</template>

<script>
import Redraw from "@/components/Join/Redraw.vue";
import Ad from "@/components/Join/Ad.vue";
import WishlistResult from "@/components/Join/WishlistResult.vue";
import { jsPDF } from "jspdf";
import myFont from "@/SourceSansPro-Regular-normal.js";
import { organizeCTA } from "@/config.js";
import AdTile from "../Home/AdTile.vue";
import WishlistTile from "./WishlistTile.vue";

export default {
  name: "JoinResult",
  props: ["result", "participant", "draw", "ad"],
  components: { Redraw, WishlistResult, WishlistTile, Ad, AdTile },
  data() {
    return {
      organizeCTA: organizeCTA,
      adDisplayed: false
    };
  },
  methods: {
    closeAd() {
      this.adDisplayed = true;
    },
    recommendations() {
      this.$analytics.logEvent("recommendations");
    },
    pdf() {
      this.$analytics.logEvent("pdf");

      // Default export is a4 paper, portrait, using millimeters for units
      const doc = new jsPDF();

      doc.addFileToVFS("SourceSansPro-Regular-normal", myFont);
      doc.addFont("SourceSansPro-Regular-normal.ttf", "SourceSansPro-Regular", "normal");
      doc.setFont("SourceSansPro-Regular");

      doc.text("Wynik losowania " + this.draw.name, 10, 24);
      doc.text("Wylosowana osoba to: ", 10, 36);
      doc.text(this.result.name, 10, 48);

      doc.text("Szczegóły losowania: ", 10, 64);
      doc.text(doc.splitTextToSize(this.draw.intro, 120), 10, 76);

      doc.save("wynik-losowania-" + this.participant.uuid + ".pdf");
    }
  }
};
</script>

<style>
.hero {
  border-bottom-left-radius: 3em;
  border-bottom-right-radius: 6em;
}
.results {
  padding: 4px;
  background-color: #f5f2ed;
  /* background-image: url("/img/bkg-toned.jpg");
  background-size: 10%;
  background-repeat: repeat; */
  min-height: 100vh;
}

.organizer-message {
  /** use $message-background-color */
  background-color: #fac57d;
}

.results .tile.is-parent {
  padding: 0.25rem 0.75rem;
}

.results .tile.is-top {
  padding-top: 0.75rem;
  position: relative;
}

.results .primary-background {
  background-image: url("/img/bkg.jpg");
  background-repeat: repeat;
}

.results .tile.is-top article {
  min-height: 340px;
}

.results .tile.is-top button.wishlist {
  bottom: 1.5rem;
  left: 1.5rem;
  position: absolute;
}

.results .result-name h1,
.results .result-name h2 {
  color: white;
}
</style>
