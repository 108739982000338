<template>
<div class="penguins">
  <p id="intro" class="menu-label is-size-5">Uratuj Pingwiny!</p>
  <canvas id="animationCanvas"></canvas>
</div>
</template>

<script>
export default {
  mounted() {
      const canvas = document.getElementById("animationCanvas");
      const ctx = canvas.getContext("2d");
      const penguinWidth = 128;
      const penguinHeight = 128;

      // Set the canvas size
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;

      const penguins = [];
      const penguinImage = new Image();
        penguinImage.src = '/img/theme/penguins/penguin-'+ getRandomInt(1,3) +'.png'; // Replace 'penguin.png' with the path to your penguin image

      function getRandomInt(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
      }

      function createPenguin() {
        const diagonalSpeedX = 1;
        const diagonalSpeedY = 1.5;
        const rotationSpeed = 0.1;

        const penguin = {
          x: -1 * getRandomInt(1, 16) * 4,
          y: 128,
          speedX: diagonalSpeedX,
          speedY: diagonalSpeedY,
          rotation: 0,
          rotationSpeed,
        };
        penguins.push(penguin);
      }

      function drawPenguin(x, y, rotation) {
        ctx.save();
        ctx.translate(x + penguinWidth / 2, y + penguinHeight / 2);
        ctx.rotate(rotation);
        ctx.drawImage(penguinImage, -penguinWidth / 2, -penguinHeight / 2, penguinWidth, penguinHeight);
        ctx.restore();
      }

      function update() {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Create new penguins at random intervals
        if (Math.random() < 0.01) {
          createPenguin();
        }

        for (const penguin of penguins) {
          // Move the penguins diagonally
          penguin.x += penguin.speedX;
          penguin.y += penguin.speedY;
          penguin.rotation += penguin.rotationSpeed;

          // Increase the speed
          penguin.speedY += 0.01;

          drawPenguin(penguin.x, penguin.y, penguin.rotation);

          // If a penguin reaches the screen right border, remove it from the array
          if (penguin.x >= canvas.width - penguinWidth) {
            const index = penguins.indexOf(penguin);
            if (index !== -1) {
              penguins.splice(index, 1);
            }
          }

          // If a penguin reaches the screen top or bottom border, bounce it and increase speed
          if (penguin.y >= (canvas.height - 128) - penguinHeight || penguin.y <= 0) {
            penguin.speedY = -penguin.speedY; // Bounce the penguin
            penguin.speedY -= getRandomInt(5, 25) * 0.25; // Speed up the penguin when it bounces
            penguin.speedX += getRandomInt(5, 15); // Speed up the penguin when it bounces
          }
        }

        requestAnimationFrame(update);
      }

      // Make sure the image is loaded before starting the animation
      penguinImage.onload = function() {
        update();
      };

      // canvas.addEventListener('click', () => { console.log('helo')})
  }
}
</script>

<style>
      .penguins canvas#animationCanvas {
        border: 0;
        padding: 0;
        margin: 0;
        background-image: url('/img/theme/penguins/bkg.jpg');
        background-size: cover;
      }

      p#intro {
        color: #3bc0d1;
        position: absolute !important;
        bottom: 96px;
        font-weight: bold;
        text-align: center;
        width: 100%;
      }
</style>
