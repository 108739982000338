<template>
  <div class="container is-max-desktop">
    <div v-if="isLoading === false && drawExists && !isFinished">
      <Name v-if="!participant" :participants="draw.participants" :draw="draw" />
      <Welcome v-if="step == 'welcome' && participant" :participant="participant" />
      <Draw
        v-if="step == 'draw'"
        :theme="draw.theme"
        :optionsTotal="Math.max(draw.participants.length - 1, 1)"
      />
      <Result
        v-if="step == 'result'"
        :result="result"
        :participant="participant"
        :draw="draw"
        :ad="ad"
      />
      <Payment v-if="step == 'payment'" />
    </div>

    <div v-if="isFinished" class="container is-fluid">
      <div class="section">
        <div class="block">
          <figure class="image is-128x128">
            <img src="/icons/surprised.svg" />
          </figure>
        </div>
        <h1 class="title">Upsss...</h1>
        <p>
          Losowane <strong>{{ draw.name }}</strong> jest zakończone
        </p>
      </div>
    </div>

    <div v-if="isLoading === true" class="container is-fluid">
      <Spinner title="Jeszcze chwila" subtitle="Właśnie dołączasz do losowania" />
    </div>

    <div v-if="isLoading === false && !drawExists" class="container is-fluid">
      <div class="section">
        <div class="block">
          <figure class="image is-128x128">
            <img src="/icons/exclamation.svg" />
          </figure>
        </div>
        <h1 class="title">Upss...<br />losowanie nie istnieje</h1>
        <h2 class="subtitle">Sprawdź link i spróbuj ponownie</h2>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Name from "@/components/Join/Name.vue";
import Welcome from "@/components/Join/Welcome.vue";
import Draw from "@/components/Join/Draw.vue";
import Result from "@/components/Join/Result.vue";
import Payment from "@/components/Join/Payment.vue";
import Spinner from "@/components/Spinner.vue";
import { getRandomAd } from "@/config.js";

export default {
  name: "JoinMain",
  components: {
    Name,
    Welcome,
    Draw,
    Result,
    Spinner,
    Payment
  },
  data() {
    return {
      ad: getRandomAd()
    };
  },
  computed: {
    step() {
      return this.$store.state.participant.step;
    },
    draw() {
      return this.$store.state.participant.draw;
    },
    participant() {
      return this.$store.state.participant.current;
    },
    result() {
      return this.$store.state.participant.result;
    },
    isFinished() {
      return (
        this.isLoading === false &&
        this.$store.state.participant.draw &&
        this.$store.state.participant.draw.participants.length === 0 &&
        !this.$store.state.participant.current
      );
    },
    isLoading() {
      return this.$store.state.participant.loading;
    },
    drawExists() {
      return this.$store.state.participant.loading === false && this.$store.state.participant.draw;
    }
  },
  created() {
    const id = this.$route.params.id;
    this.$store.dispatch("participant/getDraw", { id: id });
  }
};
</script>

<style>
.buttons button {
  width: 100%;
}

body {
  background-color: #f5f2ed;
}
</style>
