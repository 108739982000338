<template>
  <div class="ads vh100" :style="styleBkg">
    <div class="block">
      <h1 class="title is-4" :style="styleText">w Święta<br/><small>pamiętaj o innych...</small></h1>
    </div>

    <div class="block teaser" :style="styleText">
      <p class="is-size-2">{{ ad.title }}</p>
    </div>

    <div class="section floating-button">
      <b-button v-if="buttonEnabled" class="is-primary" style="width: 100%" @click="close">Przejdź dalej</b-button>
      <b-button v-if="!buttonEnabled" style="width: 100%;"><i class="p-4 is-large fa-2x fas fa-spinner fa-pulse"></i></b-button>
    </div>
  </div>

</template>
<script>
export default {
  props: ['ad', 'close'],
  data() {
    return {
      buttonEnabled: false,
    }
  },
  computed: {
    styleText() { return { color: this.ad.colorText }; },
    styleBkg() { return { backgroundColor: this.ad.colorBkg, }; }
  },
  mounted() {
    setTimeout(() => this.buttonEnabled = true, 3000);
  }
}
</script>

<style>
.ads { text-align: center; padding-top: 3rem; }
.ads .floating-button { position: absolute; bottom: 16px; width: 100%; }
.ads .teaser { margin-top: 5rem; padding: 1rem; }
</style>
