<template>
  <article class="section">
    <p class="is-size-4 has-text-centered">Czy wynik jest okej?</p>

    <ul class="options">
      <li><b-button @click="finish" style="width: 100%">Tak, dziękuję</b-button></li>
      <li><b-button @click="redraw" style="width: 100%">Nie, powtórzmy losowanie</b-button></li>
    </ul>
  </article>
</template>

<script>
export default {
  methods: {
    finish() {
      this.$analytics.logEvent("participant_result_confirm");
      this.$store.dispatch("participant/acceptResult");
      window.scrollTo(0, 0);
    },
    redraw() {
      this.$analytics.logEvent("participant_result_redraw");
      this.$store.dispatch("participant/paymentStart");
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style></style>
