<template>
  <div class="vh100">
    {{ /* Drawing section */ }}
    <div v-if="open" @click="close" :class="drawingThemeClass">
      {{ /* Drawing section */ }}
      <div v-if="theme == 'santa'"><Santa /></div>
      <div v-else-if="theme == 'presents'"><Presents /></div>
      <div v-else-if="theme == 'penguins'"><Penguins /></div>
      <div v-else-if="theme == 'snowflakes'"><Snowflakes /></div>
      <div v-else-if="theme == 'drawer'"><Drawer /></div>
      <div v-else-if="theme == 'socks2'"><Socks2 /></div>

      <div v-else-if="theme == 'xmas'">
        <div class="title">
          <h1>Teraz wybierz los</h1>
        </div>
      </div>

      <div v-else-if="theme == 'elves'">
        <div class="title">
          <h1>Wybierz Elfa,<br /><small>a dowiesz się komu przygotujesz prezent...</small></h1>
        </div>
      </div>

      <div v-else-if="theme == 'socks'">
        <div class="title section has-text-centered">
          <h1>Wybierz Skarpetke,<br /><small>a dowiesz się komu przygotujesz prezent...</small></h1>
        </div>
        <div class="socks"></div>
      </div>

      <div v-else-if="theme == 'ballons'">
        <div class="title">
          <h1>Wybierz balon,<br /><small>a dowiesz się komu przygotujesz prezent...</small></h1>
        </div>
      </div>

      <div v-else>
        <div class="title">
          <h1>Teraz wybierz los</h1>
        </div>
        <div style="margin-top: 3rem">
          <div
            :class="getRandomBgColor()"
            v-for="option in optionsTotal"
            :key="option"
            @click="close"
          >
            <p class="has-text-centered">?</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!open" class="section">
      <div class="block">
        <figure class="image is-128x128">
          <img src="/icons/gift.svg" />
        </figure>
      </div>

      <h1 class="title">Już wiemy komu zrobisz prezent!</h1>

      <BaseErrors :errors="errors" />

      <div class="block">
        <form @submit.prevent>
          <b-field
            label="Zanim przejdziesz dalej, możesz podzielić się tym co chcesz dostać. To pomoże innym!"
            label-position="top"
          >
            <b-input maxlength="256" type="textarea" v-model="wishlist"></b-input>
          </b-field>

          <ul class="options">
            <li>
              <b-button @click="next" class="is-primary" style="width: 100%">{{
                wishListButtonLabel
              }}</b-button>
            </li>
          </ul>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import draw from "../../api/draw";
import BaseErrors from "@/components/BaseErrors";
import Santa from "@/components/Join/Theme/Santa";
import Presents from "@/components/Join/Theme/Presents";
import Penguins from "@/components/Join/Theme/Penguins";
import Snowflakes from "@/components/Join/Theme/Snowflakes";
import Socks2 from "@/components/Join/Theme/Socks2";
import Drawer from "@/components/Join/Theme/Drawer";

const colors = [
  "has-background-success",
  "has-background-warning	",
  "has-background-danger",
  "has-background-info",
  "has-background-link",
  "has-background-primary",
  "has-background-danger-light",
  "has-background-success-light",
  "has-background-info-light",
  "has-background-info-dark"
];

export default {
  name: "JoinDraw",
  props: ["theme", "optionsTotal"],
  components: { BaseErrors, Santa, Drawer, Presents, Penguins, Snowflakes, Socks2 },
  data() {
    return {
      open: true,
      wishlist: "",
      wishListButtonLabel: "Dalej",
      errors: []
    };
  },
  computed: {
    drawingThemeClass() {
      return "drawing-theme-" + this.theme;
    }
  },
  methods: {
    getRandomBgColor() {
      const i = Math.floor((Math.random() * Date.now()) % colors.length);
      return "box " + colors[i];
    },
    close() {
      this.open = false;
      window.scrollTo(0, 0);
    },
    next() {
      this.errors = [];

      if (this.wishlist.length > 0) {
        this.wishListButtonLabel = "Trwa zapisywanie ...";
        draw
          .storeWishlist(
            this.$store.state.participant.draw.id,
            this.$store.state.participant.current.uuid,
            this.wishlist
          )
          .then(response => {
            this.wishListButtonLabel = "Dalej";

            if (response.errors) {
              console.log(response.errors);
              this.errors = response.errors;
              return;
            }

            this.$store.state.participant.current.wishlist = this.wishlist;
            this.$analytics.logEvent("participant_wishlist_used");
          });
      } else {
        this.$analytics.logEvent("participant_wishlist_notused");
      }

      this.$store.dispatch("participant/revealResult");
      this.$analytics.logEvent("participant_reveal_result");
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style>
.draw a {
  padding: 12px;
  margin: 12px;
  border: 1px solid black;
}

ul.options li {
  margin: 1rem 0;
}

.drawing-theme-socks .title {
  color: #a81817;
}
.drawing-theme-socks .socks {
  height: 100vh;
  background-image: url("/img/theme/socks/bkg.png");
  background-size: contain;
  background-position-y: 5em;
  background-repeat: no-repeat;
}

.drawing-theme-ballons {
  height: 100vh;
  background-image: url("/img/ballons.jpg");
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: #ffd8db;
}

.drawing-theme-ballons div.title h1 {
  padding-top: 2rem;
  text-align: center;
  width: 100%;
  display: block;
  color: #ae4537;
  /* color: #fa97a5; */
}

.drawing-theme-elves {
  height: 100vh;
  background-image: url("/img/dancing-elves.gif");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #eef4f1;
  background-position-y: 10rem;
}

.drawing-theme-elves .title {
  background-color: #e0f0e8; /*  #dcece4; */
  height: 10rem;
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #ccc;
  text-align: center;
}

.drawing-theme-elves div.title h1 {
  text-align: center;
  width: 100%;
  display: block;
  color: #7c946c;
}

.drawing-theme-xmas {
  height: 100vh;
  background-image: url("/img/xmas-tree.jpg");
  background-size: cover;
}

.drawing-theme-xmas div.title {
  background-color: black;
  opacity: 0.75;
  height: 6rem;
  display: flex;
  align-items: center;
  width: 100%;
}
.drawing-theme-xmas div.title h1 {
  text-align: center;
  color: white;
  width: 100%;
}
</style>
