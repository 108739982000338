<template>
<div class="background">
      <div class="train-container">
        <div class="train">
            <div class="train-body">
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
  mounted() {
        // Initialize variables
        const train = document.querySelector('.train-container');
        let backgroundPosition = 0;
        let trainY = 50; // Initial vertical position of the train
        let trainX = 16; // Initial vertical position of the train
        let speedX = 0.5 // Math.random() * 2 + 1; // Random speed between 1 and 3
        let speedY = Math.random() * 2 + 1; // Random speed between 1 and 3

        // Function to move the background
        function moveBackground() {
            backgroundPosition -= 1;
            document.querySelector('.background').style.backgroundPositionX = backgroundPosition + 'px';

            // Move the train up and down
            trainY += speedY;
            if (trainY >= 64 || trainY <= 32) {
                speedY = -speedY; // Reverse direction when reaching the bounds
            }
            // Move the train up and down
            trainX += speedX;
            if (trainX >= 24 || trainX <= 16) {
                speedX = -speedX; // Reverse direction when reaching the bounds
            }

            train.style.transform = `translate(${trainX}%, ${trainY}%)`;
            // train.style.transform = `+translateX(${trainX}%)`;

            requestAnimationFrame(moveBackground);
        }

        // Start the animation
        moveBackground();
  }
}
</script>

<style>
        .train-container {
            position: absolute;
            top: 15%;
            left: calc(40% - 128px);
            z-index: 2;
        }

        .train {
            width: 256px;
            height: 128px;
            position: relative;
            background-image: url('/img/theme/santa/santa.png');
            background-size: cover;
        }

        .train-body {
            width: 100%;
            height: 100%;

        }

        .background {
            width: 100%;
            height: 100%;
            background-image: url('/img/theme/santa/bkg.jpg');
            background-size: auto 100vh;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
        }
</style>
