<template>
  <div>
    <b-button @click="open">
      <span class="icon-text">
        <span v-if="wishlist" class="icon has-text-primary">
          <i class="fa fa-lightbulb"></i>
        </span>
        <span>Jaki prezent wybrać?</span>
      </span>
    </b-button>

    <b-modal v-model="isModalActive" full-screen>
      <div class="vh100 section wishlist-modal-messages" style="background-color: #f5f2ed;">
        <div v-if="wishlist" class="has-text-centered">
          <h2 class="title">{{ name }} marzy o ...</h2>

          <p style="max-width: 240px; margin: 0 auto;">
            <i class="fas fa-quote-right mb-3"></i><br />
            {{ wishlist }} <br />
            <i class="fas fa-quote-left mt-3"></i>
          </p>

          <p class="mt-5">
            <b-button class="is-primary" @click="close">Dzięki!</b-button>
          </p>
        </div>

        <article v-if="!wishlist" class="has-text-centered">
          <h2 class="title">Niestety...</h2>
          <p>
            <i> Nie wiemy jeszcze o czym marzy {{ name }}. <br />Spróbuj wrócić później. </i>
          </p>
          <p class="mt-5">
            <b-button class="is-primary" @click="close">Rozumiem</b-button>
          </p>
        </article>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["wishlist", "name"],
  data() {
    return {
      isOpened: false,
      isModalActive: false
    };
  },
  methods: {
    close() {
      this.isModalActive = false;
    },
    open() {
      this.isModalActive = true;
      this.$analytics.logEvent("participant_wishlist_open");
      if (this.wishlist && this.wishlist.length > 0) {
        this.$analytics.logEvent("participant_wishlist_open_filled");
      } else {
        this.$analytics.logEvent("participant_wishlist_open_empty");
      }
    }
  }
};
</script>

<style scoped>
.wishlist-modal-messages {
  margin-top: 20vh;
}

button.modal-close {
  background-color: black !important;
}
</style>
