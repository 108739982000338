<template>
  <div>
    <canvas id="snowfall"></canvas>
    <img src="/img/theme/snowflakes/snowflake-1.png" id="snowflake-image-1" style="display: none;">
    <img src="/img/theme/snowflakes/snowflake-2.png" id="snowflake-image-2" style="display: none;">
    <img src="/img/theme/snowflakes/snowflake-3.png" id="snowflake-image-3" style="display: none;">
    <img src="/img/theme/snowflakes/snowflake-4.png" id="snowflake-image-4" style="display: none;">
    <img src="/img/theme/snowflakes/snowflake-5.png" id="snowflake-image-5" style="display: none;">
  </div>
</template>

<script>
export default {
  mounted() {
        const canvas = document.getElementById('snowfall');
        const ctx = canvas.getContext('2d');

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const snowflakeImage = document.getElementById('snowflake-image');
        const snowflakeImages = [
            document.getElementById('snowflake-image-1'),
            document.getElementById('snowflake-image-2'),
            document.getElementById('snowflake-image-3'),
            document.getElementById('snowflake-image-4'),
            document.getElementById('snowflake-image-5'),
        ];

        const snowflakes = [];

        function random(min, max) {
            return Math.random() * (max - min) + min;
        }

        function createSnowflake() {
            return {
                x: random(0, canvas.width),
                y: random(0, canvas.height) - 64,
                size: random(64, 128), // Adjust size here
                speedY: random(1, 3),
                opacity: 1, // random(0.5, 1),
                image: snowflakeImages[Math.floor(random(0,5))]
            };
        }

        function drawSnowflakes() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);

            for (const snowflake of snowflakes) {
                ctx.globalAlpha = snowflake.opacity;
                ctx.drawImage(snowflake.image, snowflake.x, snowflake.y, snowflake.size, snowflake.size);

                snowflake.y += snowflake.speedY;

                if (snowflake.y > canvas.height) {
                    snowflake.y = -128;
                    snowflake.x = random(0, canvas.width) - 64;
                }
            }

            requestAnimationFrame(drawSnowflakes);
        }

        function init() {
            for (let i = 0; i < 16; i++) {
                snowflakes.push(createSnowflake());
            }

            drawSnowflakes();
        }

        window.addEventListener('resize', () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        });

        init();
  }
}
</script>

<style>
        canvas#snowfall {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-image: url('/img/theme/snowflakes/bkg.jpg');
            background-size: cover;
            background-color: black;
        }
</style>
