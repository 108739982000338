<template>
  <div class="sock2">
    <div class="scene-socks2">
      <h1 class="sock-intro is-size-3">Złap skarpetkę!</h1>
      <div class="mask"></div>
      <div class="pic" id="pic"></div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    const scene = document.querySelector(".scene-socks2");

    function getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    function createSock() {
      const width = getRandomInt(4, 6) * 32;
      const present = document.createElement("div");
      present.classList.add("sock");
      present.classList.add("sock-" + getRandomInt(1, 6));
      present.style.left = getRandomInt(0, window.innerWidth - width) + "px";
      present.style.animationDuration = getRandomInt(1500, 2500) + "ms";
      present.style.width = width + "px";
      present.style.height = present.style.width;
      present.style.transform = "rotate(" + getRandomInt(1, 20) * 7.5 + "deg)";
      scene.appendChild(present);

      present.addEventListener("animationend", () => {
        scene.removeChild(present);
      });
    }

    function throwSocks() {
      const presentsCount = getRandomInt(3, 5);
      for (let i = 0; i < presentsCount; i++) {
        createSock();
      }
      setTimeout(throwSocks, getRandomInt(1000, 2000));
    }

    throwSocks();
  }
};
</script>

<style>
.scene-socks2 {
  background-image: url("/img/theme/presents/bkg.jpg");
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.sock2 .mask {
  width: 100%;
  height: 100vh;
  background-color: white;
  opacity: 0.15;
}

.sock2 .pic {
  background-color: transparent;
  background-image: url("/img/theme/socks2/elf.png");
  background-size: contain;
  background-position-x: 50%;
  width: 100%;
  background-repeat: no-repeat;
  height: 320px; /* change depending on image */
  position: absolute;
  bottom: 0;
  border-bottom: 12px solid #423628;
}

.sock2 h1.sock-intro {
  position: absolute;
  text-align: center;
  width: 100%;
  background-color: black;
  opacity: 0.8;
  color: white;
  z-index: 100;
  padding: 12px 0;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  top: 20%;
}

.sock2 .sock-1 {
  background-image: url("/img/theme/socks2/sock-1.png");
}
.sock2 .sock-2 {
  background-image: url("/img/theme/socks2/sock-2.png");
}
.sock2 .sock-3 {
  background-image: url("/img/theme/socks2/sock-3.png");
}
.sock2 .sock-4 {
  background-image: url("/img/theme/socks2/sock-4.png");
}
.sock2 .sock-5 {
  background-image: url("/img/theme/socks2/sock-5.png");
}
.sock2 .sock-6 {
  background-image: url("/img/theme/socks2/sock-6.png");
}
.sock2 .sock {
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  animation: throw linear;
  animation-fill-mode: forwards;
  z-index: 200;
}

@keyframes throw {
  0% {
    top: 60%;
    width: 48px;
    height: 48px;
  }
  100% {
    top: -64px;
  }
}
</style>
