<template>
  <div class="section vh100">
    <div v-if="step == 'intro'">
      <h1 class="title">Witaj,</h1>
      <p class="subtitle mt-1">
        Za chwilę dołączysz do losowania <strong>{{ draw.name }}</strong
        >!
      </p>

      <aside class="menu mb-5" v-if="draw.intro">
        <p class="menu-label">Od organizatora</p>
        <b-message style="white-space: pre-wrap;">{{ draw.intro }}</b-message>
      </aside>

      <div class="buttons">
        <b-button @click="step = 'name'" class="is-primary">Dalej</b-button>
      </div>
    </div>

    <div v-if="step == 'name'" class="vh100">
      <div class="block">
        <figure class="image is-128x128">
          <img src="/icons/suspect.svg" />
        </figure>
      </div>

      <div class="block">
        <h1 class="title">Kim jesteś?</h1>
        <p class="subtitle mt-1">Wybierz swoje imię z listy</p>

        <div class="block">
          <div
            v-for="(participant, index) in draw.participants"
            :key="index"
            class="box"
            @click="select(participant)"
          >
            <p class="has-text-centered">
              <a href="#">{{ participant.name }}</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JoinName",
  props: ["draw"],
  data() {
    return {
      step: "intro"
    };
  },
  methods: {
    select(participant) {
      this.$analytics.logEvent("participant_join_with_name");
      this.$store.dispatch("participant/setParticipant", participant);
      this.$store.dispatch("participant/setStep", "welcome");
      window.scrollTo(0, 0);
    }
  }
};
</script>

<style scoped>
.user-select {
  display: inline-block;
  width: 128px;
  height: 128px;
  border: 0px solid salmon;
  margin: 1.25rem;
  border-radius: 64px;
  background-color: aliceblue;

  cursor: pointer;

  -webkit-box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
  box-shadow: -10px 0px 13px -7px #000000, 10px 0px 13px -7px #000000,
    5px 5px 15px 5px rgba(0, 0, 0, 0);
}

.user-select:first-child {
  margin-left: 0;
}

.user-select p {
  text-align: center;
}
.user-select a {
  width: 100%;
  text-align: center;
  vertical-align: middle;
  line-height: 128px;
}
</style>
