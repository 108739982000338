<template>
  <div>
    <div v-if="wishlist">
      <b-collapse class="card" :open="false" animation="slide" aria-id="contentIdForA11y3">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="contentIdForA11y3"
            :aria-expanded="props.open"
          >
            <p class="card-header-title">
              Twoje marzenie to ...
            </p>
            <a class="card-header-icon">
              <i v-if="props.open" class="mr-1 fas fa-angle-up has-text-primary is-size-3"></i>
              <i v-else class="mr-1 fas fa-angle-down has-text-primary is-size-3"></i>
            </a>
          </div>
        </template>

        <div class="card-content">
          <div class="content">
            <i class="fas fa-quote-right has-text-primary mr-2"></i> {{ wishlist }}
          </div>
        </div>
        <footer class="card-footer">
          <a @click="open" class="card-footer-item">
            <span class="icon-text">
              <span class="icon">
                <i class="fa fa-pen"></i>
              </span>
            </span>
            <span>Zmień</span>
          </a>
        </footer>
      </b-collapse>
    </div>
    <div v-else class="has-text-centered box">
      <p class="subtitle mb-4">Chcesz otrzymać wymarzony prezent?</p>
      <img src="/img/home-2.jpg" style="max-width: 192px" />
      <p>Napisz o czym marzysz, to pomoze innym przygotować bardziej trafiony prezent.</p>
      <b-button @click="open" class="is-primary mt-4">
        <span class="icon-text">
          <span class="icon">
            <i class="fa fa-pen"></i>
          </span>
          <span>Podziel się pomysłem</span>
        </span>
      </b-button>
    </div>
    <b-modal v-model="isModalActive" full-screen>
      <div
        class="vh100 section"
        style="max-width: 640px; background-color: #f5f2ed; margin: 0 auto;"
      >
        <article class="has-text-centered mt-5">
          <form @submit.prevent>
            <b-field
              label="Możesz podzielic się tym co chcesz dostać. To pomoże innym!"
              label-position="top"
            >
              <b-input class="mt-5" maxlength="256" type="textarea" v-model="wishlist"></b-input>
            </b-field>

            <BaseErrors :errors="errors" />

            <ul class="options">
              <li>
                <b-button @click="save" class="is-primary" style="width: 100%">
                  {{ buttonLabel }}
                </b-button>
              </li>
            </ul>
          </form>
        </article>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BaseErrors from "../BaseErrors.vue";
import draw from "../../api/draw";

export default {
  components: { BaseErrors },
  data() {
    return {
      isFolded: false,
      isModalActive: false,
      errors: [],
      buttonLabel: "Zapisz",
      wishlist: this.$store.state.participant.current.wishlist
    };
  },
  methods: {
    open() {
      this.isModalActive = true;
      this.$analytics.logEvent("participant_wishlist_from_results_form");
    },
    save() {
      this.errors = [];

      this.buttonLabel = "Trwa zapisywanie ...";
      draw
        .storeWishlist(
          this.$store.state.participant.draw.id,
          this.$store.state.participant.current.uuid,
          this.wishlist
        )
        .then(response => {
          this.buttonLabel = "Zapisz";

          if (response.errors) {
            console.log(response.errors);
            this.errors = response.errors;
            return;
          }

          this.isModalActive = false;
          if (this.wishlist.length > 0) {
            this.$analytics.logEvent("participant_wishlist_from_results_used");
          } else {
            this.$analytics.logEvent("participant_wishlist_from_results_removed");
          }
        });
    }
  }
};
</script>

<style>
button.modal-close {
  background-color: black !important;
}
</style>
