<template>
  <div class="section vh100">
    <div class="block">
      <figure class="image is-128x128">
        <img src="/icons/wave.svg" />
      </figure>
    </div>
    <div class="block">
      <h1 class="title">Witaj {{ participant.name }}!</h1>
      <p class="subtitle mt-1">W następnym kroku wylosujesz dla kogo przygotujesz prezent</p>

      <div class="buttons">
        <b-button type="is-primary" @click="start">Rozpocznij losowanie</b-button>
      </div>
    </div>
    <div class="block">
      <p>
        <a @click="changeName">Nie jesteś <strong>{{ participant.name }}</strong>? Wybierz swoje imię ponownie.</a>
      </p>
    </div>
  </div>
</template>

<script>
import Draw from './Draw.vue';
export default {
  components: { Draw },
  name: 'JoinWelcome',
  props: ['participant'],
  methods: {
    changeName() {
      this.$analytics.logEvent("participant_change_name");
      this.$store.dispatch('participant/unsetParticipant');
      window.scrollTo(0,0);
    },
    start() {
      this.$analytics.logEvent("participant_start");
      this.$store.dispatch('participant/join');
      window.scrollTo(0,0);
    },
  },
};
</script>

<style>

</style>
